import React, {useState} from "react";

import api from "../utils/api";

import {useFormik} from "formik";
import {TextInput} from "@mantine/core";

import ErrorMessage from "../components/ErrorMessage";
import Section from "../components/Section";
import Layout from "../components/Layout";

import appGoogleImg from "../images/appGoogle.jpg";
import appStoreImg from "../images/appStore.jpg";

const Forgot = (props) => {
	const [error, setError] = useState("");
	const [successfulSending, setSuccessfulSending] = useState(false);

	const validate = (values) => {
		const errors = {};
		if (!values.email) {
			errors.email = "Sa ei sisestanud kasutajanime";
		} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = "Vale emaili aadress";
		}

		return errors;
	};

	const formik = useFormik({
		initialValues: {
			email: "",
		}, validate: validate, validateOnChange: false, onSubmit: (values) => {
			setSuccessfulSending(false);
			const validationRes = validate(values);
			if (validationRes.email) {
				// если есть ошибка
				return;
			}
			api
				.forgotPass(values)
				.then(() => {
					setSuccessfulSending(true);
					formik.resetForm();
					// navigate('/login')
				})
				.catch((err) => {
					console.log(err);
					setError(err);
				});
		},
	});

	function handleChange(e) {
		formik.handleChange(e);
		setSuccessfulSending(false);
	}

	return (<Layout location={props.location}>
			<Section title="">
				{successfulSending ? (<div
						className={"border border-green rounded-md w-full max-w-sm p-2 mb-5"}
					>
						<p className={"text-sm"}>Kinnituskiri on saadetud teie e-postile</p>
					</div>) : ("")}
				<h3>Logi sisse</h3>
				{formik.errors.email ? (<ErrorMessage error={formik.errors.email}/>) : ("")}
				{error ? <ErrorMessage error={error}/> : ""}
				<form
					className={"w-full max-w-sm mt-10"}
					onSubmit={formik.handleSubmit}
				>
					<TextInput
						className="w-full"
						placeholder="Kasutajanimi või e-posti aadress"
						id="email"
						name="email"
						type="email"
						size="lg"
						value={formik.values.email}
						onChange={handleChange}
						styles={{input: {"&:focus": {border: "1px solid #7AC142"}}}}
					/>
					<button
						className={"bg-green text-white w-full py-3 rounded-md mt-3 text-sm"}
						type="submit"
					>
						Taotleda parool
					</button>
				</form>
				<div
					className="flex flex-col md:flex-row items-center justify-between border-t  pt-8 mt-10 w-full text-sm">
					<p className="max-w-sm text-center md:text-left">
						Dr Simeonsi dieedi tugikeskus mobiilirakendus on saadaval siin:
					</p>
					<div className="flex">
						<a
							target={"_blank"}
							rel="noreferrer"
							href={"https://play.google.com/store/apps/details?id=com.shternconsulting.simeonsdiet.app"}
							className="max-w-2xl w-full h-auto mr-3"
						>
							<img src={appGoogleImg} alt="GooglePlay"/>
						</a>
						<a
							target={"_blank"}
							rel="noreferrer"
							href={"https://apps.apple.com/us/app/dr-simeonsi-dieedi-tugikeskus/id1435077850?ls=1"}
							className="max-w-2xl w-full h-auto m-0"
						>
							<img src={appStoreImg} alt="AppStore"/>
						</a>
					</div>
				</div>
			</Section>
		</Layout>);
};

export default Forgot;
